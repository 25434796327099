import cn from "classnames";
import React, {useEffect, useState} from "react";
import useRemove from "../../../../lib/crud/useRemove";
import useIsMounted from "../../../../lib/useIsMounted";

import s from "./Row.module.scss";
import checkPermission from "../../../../lib/checkPermission";
import icon_edit from "../../../../assets/icons/icon_edit.svg";
import icon_delete from "../../../../assets/icons/icon_delete.svg";
import ModalUseRemove from "../../../ui/Modal/ModalUseRemove";

const TableListViewRow = ({
                              id,
                              children,
                              attrs,
                              editLink,
                              remove = {api: null, success: null, alert: null},
                              permissionRemove,
                              permissionEdit,
                              disabled = false,
                              controlsOff = false,
                              deleteButton = true,
                              showDeleteButton = false,
                              borderNone = false,
                              tagA = true,
                              title= false,
                              setActiveModal,
                              setActiveId,
                              card,
                              setCurrentCard,
                              drop,
                              semiPageType,
                              semiPageValue,
                              additionalStyles = false
                          }) => {
    const [activeRemoveModal, setActiveRemoveModal] = useState(false);
    const [scrolling, setScrolling] = useState(false);
    const isMounted = useIsMounted();
    const {load, removeHandler} = useRemove({
        id,
        isMounted,
        confirmMessage: false,
        ...remove,
    });

    const stylesForElements = semiPageType ? {background: "#0000ff1a", width: '75px', borderLeftColor: borderNone && 'transparent'} : {
        width: '75px',
        borderLeftColor: borderNone && 'transparent'
    }

    const attrsMerge = {
        ...attrs,
        className: semiPageValue ? cn(s.table_semi_row, attrs && attrs.className,
            {[s.trLoad]: load},
            {[s.trDisabled]: disabled}) : cn(s.table_row, attrs && attrs.className,
            {[s.trLoad]: load},
            {[s.trDisabled]: disabled}),
    };

    const dragStartHandler = (e, card) => {
        setCurrentCard(card)
    }

    const dragEndHandler = (e) => {

    }

    const dragOverHandler = (e) => {
        e.preventDefault();
    }

    useEffect(() => {
        if (!scrolling) return;

        const interval = setInterval(() => {
            if (scrolling) {
                window.scrollBy(0, scrolling);
            }
        }, 50);

        return () => clearInterval(interval);
    }, [scrolling]);

    return card ? <tr {...attrsMerge}
                      onDragStart={(e) => dragStartHandler(e, card)}
                      onDragEnd={(e) => dragEndHandler(e)}
                      onDragOver={(e) => dragOverHandler(e)}
                      onDrop={(e) => {
                          drop(e, card);
                          setScrolling(false);
                      }}
                      draggable={true}
    >
        {children}

        {showDeleteButton && (
            checkPermission(permissionRemove) && (
                <td style={semiPageType ? {background: "#0000ff1a"} : {}}>
                    <div className="table-controls">
                        <button
                            type="button"
                            className={`btn btn-action s-circle ${s.btn_danger}`}
                            onClick={() => setActiveRemoveModal(true)}
                        >
                            <i className="icon icon-delete"/>
                        </button>
                    </div>
                </td>
            ))}

        {!controlsOff && (
            <td style={stylesForElements}>
                <div className="table-controls">
                    {editLink ? checkPermission(permissionEdit) && tagA ? (
                        <a href={editLink || '/'} className="mr-2">
                            <img src={icon_edit} alt="Icon edit"/>
                        </a>
                    ) : (
                        <div className="mr-2" onClick={() => {
                            setActiveModal(true);
                            setActiveId(id);
                        }}>
                            <img src={icon_edit} style={{maxWidth: 'max-content'}} alt="Icon edit"/>
                        </div>
                    ) : null}
                    {deleteButton && (
                        checkPermission(permissionRemove) && (
                            <button type="button" className={s.btn_danger}
                                    onClick={() => setActiveRemoveModal(true)}>
                                <img src={icon_delete} alt="icon delete"/>
                            </button>
                        )
                    )}
                </div>
            </td>
        )}
        {activeRemoveModal && (
            <ModalUseRemove
                active={activeRemoveModal}
                setActive={setActiveRemoveModal}
                removeHandler={removeHandler}
            />
        )}
    </tr> : <tr {...attrsMerge}>
        {children}
        {showDeleteButton && (
            checkPermission(permissionRemove) && (
                <td>
                    <div className="table-controls">
                        <button
                            type="button"
                            className={`btn btn-action s-circle ${s.btn_danger}`}
                            onClick={() => setActiveRemoveModal(true)}
                        >
                            <i className="icon icon-delete"/>
                        </button>
                    </div>
                </td>
            ))}

        {!controlsOff && (
            <td style={{width: '75px', borderLeftColor: borderNone && 'transparent'}}>
                <div className="table-controls">
                    {
                        editLink
                            ? checkPermission(permissionEdit) ? tagA
                                    ? <a href={editLink || '/'} className="mr-2">
                                        <img src={icon_edit} alt="Icon edit"/>
                                    </a>
                                    : <div className="mr-2" onClick={() => {
                                        setActiveModal(true);
                                        setActiveId(id);
                                    }}>
                                        <img src={icon_edit} style={{maxWidth: 'max-content'}} alt="Icon edit"/>
                                    </div>
                                : null
                            : null
                    }
                    {deleteButton && (
                        checkPermission(permissionRemove) && (
                            <button type="button" className={s.btn_danger} onClick={() => setActiveRemoveModal(true)}>
                                <img src={icon_delete} alt="icon delete"/>
                            </button>
                        )
                    )}
                </div>
            </td>
        )}
        {activeRemoveModal && (
            <ModalUseRemove
                title={title}
                active={activeRemoveModal}
                setActive={setActiveRemoveModal}
                removeHandler={removeHandler}
                additionalStyles={additionalStyles}
            />
        )}
    </tr>;
};

export default TableListViewRow;