import React, { useState } from "react";
import s from "./Modal.module.scss";
import Button from "../Button";
import Loader from "../Loader";

const ModalUseRemove = ({ active, setActive, title, removeHandler, additionalStyles }) => {
    const [load, setLoad] = useState(false);

    const removeItem = () => {
        setLoad(true);

        removeHandler()
    };

    return (
        <div
            className={active ? `${s.modal} ${s.active}` : s.modal}
            onClick={() => setActive(false)}
        >
            <div
                className={s.modal__content}
                style={additionalStyles.container || { minWidth: 'auto' }}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={s.modal__header}
                     style={additionalStyles.header}>
                    <div className={s.modal__title} style={{ textAlign: "center", marginRight: 0 }}>
                        {title ? title : 'Видалити?'}
                    </div>
                </div>
                <div className={s.modal__footer}>
                    <div className={s.modal__group} style={{ justifyContent: "center" }}>
                        <div>
                            <Button border onClick={() => setActive(false)}>Ні, лишити!</Button>
                        </div>
                        <div>
                            <Button red disabled={load} onClick={removeItem}>Так, видалити!</Button>
                        </div>
                    </div>
                </div>
            </div>
            {load && <Loader />}
        </div>
    );
};

export default ModalUseRemove;